import { unstable_createMuiStrictModeTheme, responsiveFontSizes } from '@mui/material/styles';
import { grey, blueGrey, green, red, amber, yellow } from '@mui/material/colors';


// Create a theme instance.
const mainTheme = unstable_createMuiStrictModeTheme({
    palette: {
        mode: 'dark',
        primary: {
            light: grey[700],
            main: grey[800],
            dark: grey[900],
        },
        secondary: {
            light: green[400],
            main: green[500],
            dark: green[700],
        },
        info: {
            main: blueGrey[500],
        },
        error: {
            main: red[400],
        },
        warning: {
            main: yellow['A700'],
        },
        background: {
            default: grey[900],
        },
    },
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    background: 'radial-gradient(#fff1 10%, #fff3)',
                },
            }
        }
    }
});

export default responsiveFontSizes(mainTheme);
