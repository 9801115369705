import React from 'react';
import type { AppProps } from 'next/app';
import Script from 'next/script';

import { ThemeProvider } from '@mui/material/styles';

import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider } from '@emotion/react';
import { cache } from './_document';
import mainTheme from '../components/theme/mainTheme';
import { YMInitializer } from 'react-yandex-metrika';


const MyApp = ({ Component, pageProps }: AppProps) => {
  const locale = pageProps.locale ;
  const gaTag = locale === 'ru-RU' ? 'G-LW192HKSMZ' : 'G-8RX4JH2PJ9';

  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={mainTheme}>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${gaTag}`}
        strategy='afterInteractive'
      />
      <Script id='google-analytics' strategy='afterInteractive'>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${gaTag}');
        `}
      </Script>
        <YMInitializer accounts={[88237493, 88431151]} options={{ clickmap: true, trackLinks: true, accurateTrackBounce: true, webvisor: true }} version='2' />
        <CssBaseline />
        <Component {...pageProps} />
      </ThemeProvider>
    </CacheProvider>
  )
}

export default MyApp;