import Document, { Html, Head, Main, NextScript, DocumentContext } from 'next/document';
import React from 'react';
import createEmotionServer from '@emotion/server/create-instance';
import createEmotionCache from '../components/theme/createemotioncache';

export const cache = createEmotionCache();
const { extractCriticalToChunks } = createEmotionServer(cache);

class MyDocument extends Document {
    static async getInitialProps(ctx: DocumentContext) {

        const cache = createEmotionCache();
        const originalRenderPage = ctx.renderPage;

        /* eslint-disable */
        ctx.renderPage = () =>
            originalRenderPage({
                enhanceApp: (App: any) => (props) =>
                    <App emotionCache={cache} {...props} />,
            });
        /* eslint-enable */

        const initialProps = await Document.getInitialProps(ctx);

        const emotionStyles = extractCriticalToChunks(initialProps.html);
        const emotionStyleTags = emotionStyles.styles.map((style) => (
            <style
                data-emotion={`${style.key} ${style.ids.join(' ')}`}
                key={style.key}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: style.css }}
            />
        ));

        return {
            ...initialProps,
            emotionStyleTags,
        };

    }

    render() {
        return (
            <Html>
                <Head>
                    <link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap' />
                    <link rel='icon' type='image/x-icon' href='/static/favicon.ico' />
                    <link rel='shortcut icon' href='/static/favicon.ico' />
                     <link rel='icon' type='image/svg+xml' href='/static/favicon.svg' />
                    <link rel='apple-touch-icon' sizes='57x57' href='/static/apple-icon-57x57.png' />
                    <link rel='apple-touch-icon' sizes='60x60' href='/static/apple-icon-60x60.png' />
                    <link rel='apple-touch-icon' sizes='72x72' href='/static/apple-icon-72x72.png' />
                    <link rel='apple-touch-icon' sizes='76x76' href='/static/apple-icon-76x76.png' />
                    <link rel='apple-touch-icon' sizes='114x114' href='/static/apple-icon-114x114.png' />
                    <link rel='apple-touch-icon' sizes='120x120' href='/static/apple-icon-120x120.png' />
                    <link rel='apple-touch-icon' sizes='144x144' href='/static/apple-icon-144x144.png' />
                    <link rel='apple-touch-icon' sizes='152x152' href='/static/apple-icon-152x152.png' />
                    <link rel='apple-touch-icon' sizes='180x180' href='/static/apple-icon-180x180.png' />
                    <link rel='icon' type='image/png' sizes='192x192' href='/static/android-icon-192x192.png' />
                    <link rel='icon' type='image/png' sizes='32x32' href='/static/favicon-32x32.png' />
                    <link rel='icon' type='image/png' sizes='96x96' href='/static/favicon-96x96.png' />
                    <link rel='icon' type='image/png' sizes='16x16' href='/static/favicon-16x16.png' />
                    <link rel='icon' type='image/vnd.microsoft.icon' href='/static/favicon.ico' />
                    <link rel='mask-icon' href='/static/safari-pinned-tab.svg' color='#4caf50' />
                    <meta name='msapplication-TileColor' content='#212121' />
                    <meta name='msapplication-TileImage' content='/static/ms-icon-144x144.png' />
                    <meta name='theme-color' content='#212121' />
                    <meta name='msapplication-TileColor' content='#388e3c' />
                    {(this.props as any).emotionStyleTags}
                </Head>
                <body>
                    <Main />
                    <NextScript />
                </body>
            </Html>
        )
    }
};

export default MyDocument;